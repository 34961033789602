import styled, { css } from "styled-components";

export const TileSectionWrap = styled.div`
  * {
    font-family: 'Roboto', sans-serif;
  }
  max-width: 1230px;
  padding: 96px 30px;
  margin: 0 auto;
  @media (max-width: 992px) {
    max-width: 830px;
    padding: 56px 30px;
  }
  @media (max-width: 600px) {
    max-width: 536px;
    padding: 40px 15px 56px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const TileSectionHeadingWrap = styled.div`
  max-width: 770px;
  margin: 0 auto 48px;
`

export const TileSectionKicker = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #002DC2;
  margin-bottom: 24px;
`

export const TileSectionHeading = styled.p`
  font-family: 'Roboto', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #101828;
  margin-bottom: 16px;
`

export const TileSectionSubheading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475467;
`

export const TileGrid = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: 10px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  @media (max-width: 992px) {
    margin-top: 8px;
  }
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 10px!important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset!important;
    height: unset!important;
  } 
  ${({ showScrollToTop }) => `
    visibility: ${ showScrollToTop ? 'visible' : 'hidden' };
    opacity: ${ showScrollToTop ? '100%' : '0%' };
  `}
`
