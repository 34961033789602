import styled, { css } from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const MobileWrapper = styled.div`
  max-width: 770px;
  margin: 0px auto;
  padding: 56px 15px 175px;
  overflow: scroll;
  position: relative;
  height: 100%;
  max-height: calc(100vh - 170px);
`

export const MobileTitle = styled.div`
  color: ${color.grey[900]};
  ${font('display', 'xxs', '700')}
  padding-bottom: 24px;
`

export const MobileDropdownList = styled.div`
  display: grid;
  border-top: 1px solid ${color.grey[200]};
  overflow-y: scroll;
`

export const MobileFilterTitle = styled.div`
  color: ${color.grey[900]};
  ${font('text', 'reg', '700')}
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  svg {
    path {
      fill: ${color.common.black};
    }
    transform: rotate(180deg);
    transition: transform 150ms ease;
  }
  ${({ active }) => active && css`
    svg {
      transform: rotate(0deg);
      transition: transform 150ms ease;
    }
  `}
`

export const OverlayButtonWrap = styled.div`
  position: absolute;
  bottom: 56px;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 16px 16px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  div {
    button {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
    bottom: 40px;
    #select {
      grid-area: 1 / 1 / 2 / 3;
    }
    #clear {
      grid-area: 2 / 1 / 2 / 3;
    }
  }
`

export const MobileDropdownWrap = styled.div`
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid ${color.grey[200]};
`

export const MobileCount = styled.span`
  color: ${color.common.workgridBlue};
`

export const MobileFilterItem = styled.div`
  padding: 8px 16px;
  color: ${color.common.black};
  &:hover .checkmark {
    background-color: ${color.grey[100]};
  }
  input {
    ${font("text", "reg", "400")}
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    margin-right: 16px;
  }
  label {
    margin-left: 36px;
    position: relative;
    width: 100%;
    display: block;
    cursor: pointer;
    &:hover  {
      color: ${color.common.workgridBlue};
    }
    &.all {
      ${font("text", "reg", "500")}
    }
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: -36px;
    height: 18px;
    width: 18px;
    border: 1px solid ${color.grey[300]};
    border-radius: 4px;
    background-color: white;
    &:focus-visible {
      outline: 2px solid orange;
    }
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .select input:checked ~ .checkmark:after {
    display: block;
  }
  .select .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3.5px;
    height: 8px;
    border: solid ${color.common.white};
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .active {
    .checkmark {
      background-color: ${color.common.workgridBlue};
      border: 1px solid ${color.common.workgridBlue};
    }
  }
`
