import styled, { css } from "styled-components";
import { font } from "../../../atoms/typography";
import { color } from "../../../atoms/colors";

export const TileWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 2px solid ${color.grey[200]};
  height: 100%;
  transition: box-shadow 300ms ease;
  background-color: ${color.common.white};
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  ${({ isNew }) => isNew && css`
    border: 2px solid ${color.common.emphasisPink};
    border-radius: 0px 8px 8px;
  `}
  @media (min-width: 577px) {
    min-height: 262px;
  }
`

export const NewTab = styled.div`
  position: absolute;
  ${font("kicker", "sm", "700")}
  margin-top: -22px;
  padding: 4px 10px;
  color: ${color.common.white};
  background: ${color.common.emphasisPink};
  border-radius: 6px 6px 0 0;
`

export const TileIcon = styled.div`
  height: 64px;
  width: 64px;
`

export const TextWrap = styled.div`
  display: grid;
  gap: 8px;
`

export const TileHeading = styled.span`
  ${font('display', 'xxs', '700')}
  color: ${color.common.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const TileSubheading = styled.span`
  ${font('text', 'reg', '400')}
  color: ${color.grey[600]};
    display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
