import styled from "styled-components"
import { color } from "../../../atoms/colors"
import { font } from "../../../atoms/typography"

export const NoPosts = styled.div`
  margin: 16px 0 64px;
`

export const Sorry = styled.div`
  ${font('display', 'md', '700')}
  color: ${color.grey[900]};
  margin-bottom: 16px;
  @media (max-width: 992px) {
    ${font('display', 'sm', '700')}
  }
  @media (max-width: 576px) {
    ${font('display', 'xs', '700')}
  }
`

export const DifferentSearch = styled.div`
  ${font('text', 'lg', '400')}
  color: ${color.grey[600]};
`

export const Count = styled.div`
  ${font('text', 'lg', '400')}
  color: ${color.grey[600]};
  margin-bottom: 16px;
`

export const ViewAll = styled.div`
  ${font('text', 'reg', '700')}
  line-height: 16px;
  color: ${color.common.workgridBlue};
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }
`