import { Link } from "gatsby";
import styled, { css } from "styled-components";

export const TileWrap = styled.div`
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  transition: ease box-shadow 0.25s;
  position: relative;
  .tile-image {
    border-radius: 6px;
    max-height: 141px;
    aspect-ratio: 2.3;
    ${({ tileType }) => tileType === 'Icon' && css`
      height: 64px;
      width: 64px;
    `}
  }
  :hover {
    box-shadow: 0px 12px 20px 5px rgba(27, 27, 27, 0.15);
    cursor: pointer;
    svg {
      circle {
        fill: #0022B7;
      }
      path {
        fill: white;
      }
    }
  }
`

export const LinkWrap = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PlayButtonWrap = styled.div`
  border-radius: 6px;
  max-height: 141px;
  aspect-ratio: 2.3;
  display: grid;
  width: 100%;
  position: absolute;
  left: 0;
  .playicon{
    z-index: 1;
    align-self: center;
    justify-self: center;
    svg {
      filter: drop-shadow(0px 10px 40px rgba(0, 34, 183, 0.3));
      circle, path {
        transition: ease all 0.25s;
      }
      :hover {
        circle {
          fill: #0022B7;
        }
        path {
          fill: white;
        }
      }
    }
  }
`

export const VideoImageWrap = styled.div`
  ${({ videoLink }) => videoLink && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const TileHeading = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #101828;
  margin: 24px 0 8px;
`

export const TileSubheading = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-bottom: 24px;
`

export const TileDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-bottom: 24px;
`

export const Label = styled.p`
  margin-top: auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #0022B7;
`