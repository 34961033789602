import React, {forwardRef, useEffect} from "react";

import onKeyDown from "../../../utils/onKeyDown";
import { ActiveContainer, ActiveCount, ClearButton, Dropdown, DropdownContainer, FilterItem, Title } from "../styles/UseCaseFilters.styled";
import { handleCheck } from "../../../utils/filterFunctions";
import { Chevron, ClearIcon } from "../../../utils/IconsList";

const DisplayText = ({ text, length }) => {
  return length === 0 ?
    text
    : <div>Selected <ActiveCount>({length})</ActiveCount></div>
}

const UseCaseDropdown = forwardRef(({
  type,
  text,
  active,
  array,
  clearSelect,
  title,
  setActive,
  handleKeyDown,
  activeGroup,
  setActiveGroup,
  setCurrentPage,
  isSearching
}, ref) => {
  const handleClear = () => {
    if (activeGroup.length > 0) {
      clearSelect()
      setActiveGroup([])
    }
  }

  const handleSelectAll = () => {
    if (activeGroup.length === array.length) {
      setActiveGroup([])
    } else {
      setActiveGroup(array)
    }
  }

  useEffect(() => {
    if (isSearching) {
      handleClear()
      setActive("")
    }
  }, [isSearching])

  return (
    <DropdownContainer ref={active === type ? ref : null}>
      <Title>{title}</Title>
      <Dropdown
        tabIndex={!isSearching ? 0 : -1}
        onKeyDown={(e) => onKeyDown(e, () => !isSearching && handleKeyDown(type))}
        onClick={() => (active !== type && !isSearching) ? setActive(type) : setActive("")}
        active={active === type}
        hasSelection={activeGroup.length > 0}
        isDisabled={isSearching}
      >
        <DisplayText text={text} length={activeGroup.length} />
        <Chevron />
      </Dropdown>
      {active === type &&
        <ActiveContainer>
          <FilterItem>
            <label className={`select all ${activeGroup.length === array.length ? `active` : ``}`}>
              Select all
              <input
                tabIndex={-1}
                type="checkbox"
                name='Select all'
                value='Select all'
                checked={activeGroup.length === array.length}
                aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} all ${text} Filters`}
                id={`checkbox-select-all`}
                onClick={() => handleSelectAll()}
              />
              <span
                className="checkmark"
                role="checkbox"
                aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} all ${text} Filters`}
                aria-checked={activeGroup.length === array.length}
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => handleSelectAll())}
              />
            </label>
          </FilterItem>
          {array && array.map((filterTitle) =>
            <FilterItem>
              <label className={`select ${activeGroup.includes(filterTitle) ? `active` : ``}`}>
                {filterTitle}
                <input
                  tabIndex={-1}
                  type="checkbox"
                  name={filterTitle}
                  value={filterTitle}
                  checked={activeGroup.includes(filterTitle)}
                  aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} ${filterTitle} ${text} Filter`}
                  id={`checkbox-${text}-${filterTitle}`}
                  onClick={() => handleCheck(
                    filterTitle,
                    activeGroup,
                    setActiveGroup,
                    setCurrentPage
                  )}
                />
                <span
                  className="checkmark"
                  role="checkbox"
                  aria-label={`${activeGroup.includes(filterTitle) ? "Deselect" : "Select"} ${filterTitle} ${text} Filter`}
                  aria-checked={activeGroup.includes(filterTitle)}
                  tabIndex={0}
                  onKeyDown={(e) => onKeyDown(e, () => handleCheck(
                    filterTitle,
                    activeGroup,
                    setActiveGroup,
                    setCurrentPage
                  ))}
                />
              </label>
            </FilterItem>)
          }
          <ClearButton
            onClick={() => handleClear()}
            role="button"
            tabIndex={activeGroup.length === 0 ? -1 : 0}
            onKeyDown={(e) => onKeyDown(e, () => handleClear())}
            inactive={activeGroup.length === 0}
            aria-disabled={activeGroup.length === 0}
          >
            <ClearIcon />
            Clear filter
          </ClearButton>
        </ActiveContainer>
      }
    </DropdownContainer>
  )
})

export default UseCaseDropdown
