import React, {useRef} from "react";
import {useClickAway} from "react-use";

import { color } from "../../../atoms/colors";
import { Button } from "../../../atoms/Button";

import { ClearIcon } from "../../../utils/IconsList";
import onKeyDown from "../../../utils/onKeyDown";

import SearchArea from "./UseCaseSearch";
import UseCaseDropdown from "./Dropdown";
import MobileDropdown from "./MobileDropdown";

import { MobileTitle, MobileDropdownList, MobileWrapper, OverlayButtonWrap } from "../styles/MobileDropdown.styled";
import { FilterWrap, ContentWrap, FilterOverlay, ClearButtonWrap, ClearAllButton } from "../styles/UseCaseFilters.styled";

const UseCaseFilters = ({
  integrations,
  activeIntegration,
  setActiveIntegration,
  activeUseCase,
  setActiveUseCase,
  useCases,
  userGroups,
  activeUserGroup,
  setActiveUserGroup,
  setOverlayOpen,
  isTablet,
  isScrolled,
  setCurrentPage,
  activeDropdown,
  activeKey,
  setActiveDropdown,
  setActiveKey,
  isSearching,
  isMobile,
  overlayOpen,
  scrollToTop,
  hasFilter,
}) => {
  const totalFilterCount = activeIntegration.length + activeUseCase.length + activeUserGroup.length
  const ref = useRef(null)

  let bg
  let boxShadow
  let paddingTop
  let paddingBottom
  let marginBottom

  if (isScrolled && isTablet) {
    bg = `${color.common.white}`
    paddingTop = "16px"
    paddingBottom = "8px"
    boxShadow = "0px 20px 30px rgba(16, 24, 40, 0.05)"
    marginBottom = "20px"
  } else if (!isTablet) {
    bg = "transparent"
    paddingTop = "48px"
    paddingBottom = "48px"
    boxShadow = "none"
    marginBottom = "0"
  } else {
    bg = "transparent"
    paddingTop = "32px"
    paddingBottom = "32px"
    boxShadow = "none"
    marginBottom = "0"
  }

  const filterArray = ['integrations', 'useCases', 'userGroups']
  const filters = {
    integrations: {
      type: 'integration',
      title: 'Filter by integration',
      text: 'Select integration',
      mobileText: 'Integration',
      array: integrations,
      activeGroup: activeIntegration,
      setActiveGroup: setActiveIntegration,
    },
    useCases: {
      type: 'useCases',
      title: 'Filter by use case',
      array: useCases,
      text: 'Select use case',
      mobileText: 'Use Case',
      activeGroup: activeUseCase,
      setActiveGroup: setActiveUseCase,
    },
    userGroups: {
      type: 'userGroup',
      title: 'Filter by user group',
      text: 'Select user group',
      mobileText: 'User Group',
      array: userGroups,
      activeGroup: activeUserGroup,
      setActiveGroup: setActiveUserGroup,
    }
  }

  const clearSelect = () => {
    setActiveDropdown("")
    overlayOpen && setOverlayOpen(false)
    isScrolled && scrollToTop()
  }

  const handleKeyDown = (string) => {
    activeDropdown !== string ? setActiveDropdown(string) : setActiveDropdown("")
  }

  const handleSelect = () => {
    setOverlayOpen(false)
    setActiveDropdown('')
    isScrolled && scrollToTop()
  }

  const handleClear = () => {
    setActiveIntegration([])
    setActiveUseCase([])
    setActiveUserGroup([])
    setCurrentPage(1)
    setActiveKey("")
    setActiveDropdown('')
    overlayOpen && setOverlayOpen(false)
    isScrolled && scrollToTop()
  }

  useClickAway(ref, ()=> setActiveDropdown(""))

  return (
    <FilterWrap
      style={{
        background: bg,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        boxShadow: boxShadow,
        marginBottom: marginBottom
      }}
      isTablet={isTablet}
      overlay={overlayOpen}
    >
      <ContentWrap isTablet={isTablet}>
        {!isTablet &&
          <>
            {filterArray.map(filter => (
              <UseCaseDropdown
                ref={ref}
                title={filters[filter].title}
                type={filters[filter].type}
                array={filters[filter].array.sort()}
                activeGroup={filters[filter].activeGroup}
                setActiveGroup={filters[filter].setActiveGroup}
                text={filters[filter].text}
                active={activeDropdown}
                setActive={setActiveDropdown}
                clearSelect={clearSelect}
                handleKeyDown={handleKeyDown}
                setCurrentPage={setCurrentPage}
                isSearching={isSearching}
              />
            ))}
          </>
        }
        <SearchArea
          setOverlayOpen={setOverlayOpen}
          isTablet={isTablet}
          isMobile={isMobile}
          isScrolled={isScrolled}
          setActive={setActiveDropdown}
          isSearching={isSearching}
          activeKey={activeKey}
          totalFilterCount={totalFilterCount}
          setActiveKey={setActiveKey}
        />
      </ContentWrap>
      {isTablet && overlayOpen &&
        <FilterOverlay>
          <MobileWrapper>
            <MobileTitle>Filter by category</MobileTitle>
            <MobileDropdownList>
              {filterArray.map(filter => (
                <MobileDropdown
                  text={filters[filter].mobileText}
                  type={filters[filter].type}
                  array={filters[filter].array.sort()}
                  activeGroup={filters[filter].activeGroup}
                  setActiveGroup={filters[filter].setActiveGroup}
                  active={activeDropdown}
                  setActive={setActiveDropdown}
                  handleKeyDown={handleKeyDown}
                  setCurrentPage={setCurrentPage}
                  isSearching={isSearching}
                />
              ))}
            </MobileDropdownList>
          </MobileWrapper>
          <OverlayButtonWrap>
            <div id="select" onClick={() => handleSelect()}>
              <Button ctaVariant="primary">Select Category</Button>
            </div>
            <div id="clear" onClick={() => handleClear()}>
              <Button ctaVariant="secondary">Clear All Filters</Button>
            </div>
          </OverlayButtonWrap>
        </FilterOverlay>
      }
      {hasFilter && !isTablet &&
        <ClearButtonWrap>
          <ClearAllButton onClick={() => handleClear()} onKeyDown={(e) => onKeyDown(e, () => handleClear())} role="button" tabIndex={0} aria-label="Clear all filters">
            <ClearIcon />Clear all filters
          </ClearAllButton>
        </ClearButtonWrap>
      }
    </FilterWrap>
  )
}

export default UseCaseFilters;
