import React from "react"

import { Count } from "../styles/AppsListing.styled"

const DisplayCount = ({ page, posts, postsPerPage }) => {
  const postLength = posts.length
  const pagePosts = postsPerPage ?? 9
  const slicedLength = posts.slice(
    (page - 1) * pagePosts,
    page * pagePosts
  ).length

  let displaying
  let total

  if (page === 1) {
    if (postLength > pagePosts) {
      displaying = pagePosts
      total = postLength
    } else {
      displaying = postLength
      total = postLength
    }
  } else {
    if (slicedLength >= pagePosts) {
      displaying = pagePosts
      total = postLength
    } else {
      displaying = slicedLength
      total = postLength
    }
  }

  return (
    <Count>
      Showing <strong>{displaying}</strong> out of <strong>{total}</strong>.
    </Count>
  )
}

export default DisplayCount