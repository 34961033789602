import styled, { css } from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const FilterWrap = styled.div`
  ${font('text', 'reg', '400')}
  color: ${color.restfulGrey[400]};
  width: 100%;
  transition: top 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s, position 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s, padding 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s, margin 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  ${({ isTablet, overlay }) => isTablet && !overlay && css`
    position: sticky;
    top: 100px;
    z-index: 15;
  `}
  `

export const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 0 30px;
  max-width: 506px;
  margin: 0 auto;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
  ${({ isTablet }) => isTablet && css`
    grid-template-columns: 1fr;
  `}
`

export const DropdownWrap = styled.div`
  position: relative;
`

export const Title = styled.span`
  display: block;
  color: ${color.common.black};
  ${font('text', 'sm', '500')}
`

export const Dropdown = styled.div`
  border-radius: 30px;
  padding: 10px 14px;
  border: 1px solid ${color.grey[300]};
  background: ${color.common.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${color.restfulGrey[500]};
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);   
  ${({ active }) => active && active === true ? css`
    border: 1px solid ${color.common.workgridBlue};
    svg {
      transition: rotate 150ms ease;
    }
  ` : css`
    svg {
      transition: rotate 150ms ease;
      rotate: 180deg;
      path {
        fill: ${color.restfulGrey[400]};
      }
    }
  `}
  ${({ hasSelection }) => hasSelection && css`
    color: ${color.grey[600]};
  `}
  ${({ isDisabled }) => isDisabled && css`
    color: ${color.common.disabled};
    cursor: not-allowed;
  `}
`

export const DropdownContainer = styled.div`
  display: grid;
  row-gap: 6px;
  position: relative;
`

export const SearchContainer = styled.div`
  position: relative;
  display: grid;
  ${({ isTablet }) => isTablet && css`
    grid-template-columns: 180px 1fr;
    column-gap: 40px;
  `}
  ${({ isMobile }) => isMobile && css`
    grid-template-columns: 40px 1fr;
    column-gap: 32px;
  `}
`

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${color.common.black};
  cursor: pointer;
  ${font('text', 'reg', '700')}
  svg {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
  ${({ searching }) => searching ? css`
    cursor: not-allowed;
    color: ${color.common.disabled};
    svg {
      path {
        stroke: ${color.common.disabled};
      }
    }
  ` : css`  
    &:hover {
      color: ${color.common.workgridBlue};
      svg {
        path {
          stroke: ${color.common.workgridBlue};
        }
      }
    }
  `}
`

export const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const Count = styled.span`
  position: absolute;
  right: -4px;
  top: -4px;
  border-radius: 50%;
  min-width: 24px;
  padding-left: 1px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.common.workgridBlue};
  color: ${color.common.white};
  ${font('kicker', 'sm', '900')}
`

export const SearchWrap = styled.div`
  position: relative;
  width: 100%;
  height: 46px;
  margin-top: auto;
  display: flex;
  svg {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  #search {
    position: absolute;
    left: 17px;
    bottom: 0px;
    height: 100%;
  }
  #clear {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 46px;
    height: 100%;
    cursor: pointer;
  }
  ${({ isTablet }) => isTablet && css`
    height: unset;
  `}
`

export const Search = styled.input`
  background: ${color.common.white};
  border: 1px solid ${color.grey[300]};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px;
  max-height: 46px;
  width: 100%;
  height: 46px;
  margin-top: auto;
  padding: 10px 14px 10px 48px;
  &::placeholder {
    ${font('text', 'reg', '400')}
    color: ${color.restfulGrey[400]};
  }
  ${({ isSearching }) => isSearching && css`
    border-color: ${color.common.black};
    padding-right: 48px;
  `}
`

export const ActiveContainer = styled.div`
  position: absolute;
  background-color: ${color.common.white};
  width: 100%;
  top: calc(100% + 16px);
  max-height: 300px;
  border-radius: 8px;
  padding-top: 8px;
  overflow-y: scroll;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10);
  z-index: 10;
`

export const FilterItem = styled.div`
  padding: 10px 14px;
  color: ${color.common.black};
  ${font("text", "reg", "400")}
  cursor: pointer;
  &:hover {
    background-color: ${color.grey[100]};
  }
  input {
    ${font("text", "reg", "400")}
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    margin-right: 16px;
  }
  label {
    position: relative;
    display: block;
    cursor: pointer;
    &:hover  {
      color: ${color.common.workgridBlue};
    }
    &.all {
      ${font("text", "reg", "500")}
    }
  }
  .checkmark {
    position: absolute;
    top: 2px;
    right: 0  ;
    height: 18px;
    width: 18px;
    border: 1px solid ${color.grey[300]};
    border-radius: 4px;
    background-color: white;
    &:focus-visible {
      outline: 2px solid orange;
    }
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .select input:checked ~ .checkmark:after {
    display: block;
  }
  .select .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3.5px;
    height: 8px;
    border: solid ${color.common.white};
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .active {
    .checkmark {
      background-color: ${color.common.workgridBlue};
      border: 1px solid ${color.common.workgridBlue};
    }
  }
`

export const ClearButton = styled.div`
  padding: 10px 14px;
  border-top: 1px solid ${color.grey[200]};
  ${font("text", "reg", "700")}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  ${({ inactive }) => inactive ? 
  css`
    color: ${color.common.disabled};
    cursor: not-allowed;
    svg {
      path {
        stroke: ${color.common.disabled};
      }
    }
    ` : 
  css`
    cursor: pointer;
    color: ${color.common.workgridBlue};
    &:hover {
      color: ${color.common.hoverBlue};
      svg {
        path {
          stroke: ${color.common.hoverBlue};
        }
      }
    }
  `
}
`

export const ActiveCount = styled.span`
  color: ${color.common.black};
  ${font("text", 'reg', '700')}
`

export const FilterOverlay = styled.div`
  z-index: 9999999;
  position: fixed;
  top: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
`

export const ClearButtonWrap = styled.div`
  padding: 0 30px;
  max-width: 506px;
  margin: 0 auto;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
`

export const ClearAllButton = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  ${font('text', 'reg', '700')}
  color: ${color.common.workgridBlue};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`