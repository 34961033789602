import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Tile } from "./components/Tile";
import { Button } from '@atoms'
import { 
  TileSectionWrap,
  TileSectionHeadingWrap,
  TileSectionKicker,
  TileSectionHeading,
  TileSectionSubheading,
  TileGrid,
  ScrollBtnWrapper,
} from "./styles/TileSectionStandard.styled";
import { paginator } from "../../utils/paginatorFunction";
import useScreenResponsive from "../../utils/screenResponsive";
import ComponentPagination from "../Pagination/Pagination";

const TileSectionStandard = ({ component, slug }) => {
  const {
    kicker, 
    heading, 
    subheading, 
    tiles,
    noTopPadding,
    noBottomPadding,
    pagination,
    desktopPageSize = 6,
    tabletPageSize = 6,
    mobilePageSize = 3,
    tileType,
  } = component

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPostsPerPage, setMaxPosts] = useState(desktopPageSize);

  const isMobile = useScreenResponsive(600)
  const isTablet = useScreenResponsive(992)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const updatePaginator = () => {
    if (isMobile) {
      setMaxPosts(mobilePageSize)
    } else if (isTablet) {
      setMaxPosts(tabletPageSize)
    } else {
      setMaxPosts(desktopPageSize)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    });
  }, []);
  
  useEffect(() => {
    updatePaginator();
  }, [isMobile, isTablet])

  const paginatedTileData = paginator(tiles, currentPage, maxPostsPerPage)
  const tileArray = pagination ? paginatedTileData?.data : tiles
  const paginatedTiles = tileArray?.map((tile, idx) => {
    return (
      <Tile tile={tile} tiles={tiles} key={"tile" + idx} index={idx} tileType={tileType} />
    )
  })

  return (
    <TileSectionWrap noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      {(kicker || heading || subheading) &&
        <TileSectionHeadingWrap>
          {kicker && <TileSectionKicker>{kicker}</TileSectionKicker>}
          {heading && <TileSectionHeading>{heading}</TileSectionHeading>}
          {subheading && <TileSectionSubheading>{documentToReactComponents(JSON.parse(subheading?.raw))}</TileSectionSubheading>}
        </TileSectionHeadingWrap>
      }
      <TileGrid>
        {paginatedTiles}
      </TileGrid>
      {pagination && 
        <ComponentPagination 
          current={currentPage}
          total={tiles.length}
          pageSize={maxPostsPerPage}
          func={setCurrentPage}
          marginTop={32}
        />
      }
      <ScrollBtnWrapper showScrollToTop={showScrollToTop} onClick={scrollToTop}>
        <Button ctaVariant='secondary'>Back To Top</Button>
      </ScrollBtnWrapper>
    </TileSectionWrap>
  )
}

export default TileSectionStandard
