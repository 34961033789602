import styled from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const TileSectionWrap = styled.div`
  background-color: ${color.grey[100]};
  padding: 48px 0;
  @media (min-width: 577px) {
    padding: 64px 0;
  }
  @media (min-width: 993px) {
    padding: 96px 0;
  }
`

export const Title = styled.h2`
  color: ${color.grey[900]};
  ${font('display', 'xs', '700')}
  padding: 0 30px;
  max-width: 506px;
  margin: 0 auto;
  @media(min-width: 577px) {
    ${font('display', 'sm', '700')}
    max-width: 770px;
  }
  @media (min-width: 993px) {
    ${font('display', 'md', '700')}
    max-width: 1170px;
  }
`

export const ContentWrap = styled.div`
  padding: 0 30px;
  max-width: 506px;
  margin: 0 auto;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
`

export const CountWrap = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`

export const SorryWrap = styled.div`
  display: grid;
  gap: 16px;
`

export const SorryHeading = styled.span`
  color: ${color.grey[900]};
  ${font('display', 'xxs', '700')}
  @media (min-width: 577px) {
    ${font('display', 'xs', '700')}
  }
  @media (min-width: 993px) {
    ${font('display', 'sm', '700')}
  }
`

export const SorrySubheading = styled.span`
  color: ${color.grey[600]};
  ${font('text', 'lg', '400')}
`

export const TilesWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
  @media (min-width: 577px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
  @media (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
`