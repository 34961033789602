import React from "react";
import { MobileCount, MobileDropdownWrap, MobileFilterItem, MobileFilterTitle } from "../styles/MobileDropdown.styled";
import onKeyDown from "../../../utils/onKeyDown";
import { handleCheck } from "../../../utils/filterFunctions";
import { Chevron } from "../../../utils/IconsList";

const MobileDisplayText = ({ text, length }) => {
  return length === 0 ?
    text
    : <span>{text} <MobileCount>({length})</MobileCount></span>
}

const MobileDropdown = ({
  type,
  active,
  array,
  text,
  setActive,
  handleKeyDown,
  activeGroup,
  setActiveGroup,
  setCurrentPage,
}) => {
  const handleSelectAll = () => {
    if (activeGroup.length === array.length) {
      setActiveGroup([])
    } else {
      setActiveGroup(array)
    }
  }

  return (
    <MobileDropdownWrap>
      <MobileFilterTitle
        onClick={() => (active !== type) ? handleKeyDown(type) : setActive("")}
        onKeyDown={(e) => onKeyDown(e, () => handleKeyDown(type))}
        tabIndex={0}
        role="button"
        active={active === type}
      >
        <MobileDisplayText text={text} length={activeGroup.length} />
        <Chevron />
      </MobileFilterTitle>
      {active === type &&
        <>
          <MobileFilterItem>
            <label className={`select all ${activeGroup.length === array.length ? `active` : ``}`}>
              Select all
              <input
                tabIndex={-1}
                type="checkbox"
                name='Select all'
                value='Select all'
                checked={activeGroup.length === array.length}
                aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} all ${text} Filters`}
                id={`checkbox-select-all`}
                onClick={() => handleSelectAll()}
              />
              <span
                className="checkmark"
                role="checkbox"
                aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} all ${text} Filters`}
                aria-checked={activeGroup.length === array.length}
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => handleSelectAll())}
              />
            </label>
          </MobileFilterItem>
          {array && array.map((filterTitle) =>
            <MobileFilterItem>
              <label className={`select ${activeGroup.includes(filterTitle) ? `active` : ``}`}>
                {filterTitle}
                <input
                  tabIndex={-1}
                  type="checkbox"
                  name={filterTitle}
                  value={filterTitle}
                  checked={activeGroup.includes(filterTitle)}
                  aria-label={`${activeGroup.length === array.length ? "Deselect" : "Select"} ${filterTitle} ${text} Filter`}
                  id={`checkbox-${text}-${filterTitle}`}
                  onClick={() => handleCheck(
                    filterTitle,
                    activeGroup,
                    setActiveGroup,
                    setCurrentPage
                  )}
                />
                <span
                  className="checkmark"
                  role="checkbox"
                  aria-label={`${activeGroup.includes(filterTitle) ? "Deselect" : "Select"} ${filterTitle} ${text} Filter`}
                  aria-checked={activeGroup.includes(filterTitle)}
                  tabIndex={0}
                  onKeyDown={(e) => onKeyDown(e, () => handleCheck(
                    filterTitle,
                    activeGroup,
                    setActiveGroup,
                    setCurrentPage
                  ))}
                />
              </label>
            </MobileFilterItem>
          )}
        </>
      }
    </MobileDropdownWrap>
  )
}

export default MobileDropdown
