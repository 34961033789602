const removeItem = (item, array, setFunc, pageFunc, isScrolled, scrollToTop) => {
  const index = array.indexOf(item)
  array.splice(index, 1)
  setFunc([...array])
  pageFunc(1)
  isScrolled && scrollToTop()
}

const addItem = (item, array, setFunc, pageFunc, isScrolled, scrollToTop) => {
  setFunc([...array, item])
  pageFunc(1)
  isScrolled && scrollToTop()
}

export const handleCheck = (item, array, setFunc, pageFunc, isScrolled, scrollToTop) => {
  array.includes(item)
    ? removeItem(item, array, setFunc, pageFunc, isScrolled, scrollToTop)
    : addItem(item, array, setFunc, pageFunc, isScrolled, scrollToTop)
}