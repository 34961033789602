export const formatUseCases = (useCase) => {
  let category = []
  let integrations = []
  let useCases = []
  let userGroups = []

  if (useCase?.category) {
    useCase?.category?.map((cat) => 
    category.push(cat.title)
  )}
  if (useCase?.integrations) {
    useCase?.integrations?.map((int) =>
    integrations.push(int.title)
  )}
  if (useCase?.useCases) {
    useCase?.useCases?.map((use) => 
    useCases.push(use.title)
  )}
  if (useCase?.userGroups) {
    useCase?.userGroups?.map((group) => 
    userGroups.push(group.title)
  )}

  return ({
    category: category,
    integrations: integrations,
    useCases: useCases,
    userGroups: userGroups,
    ctaInternalLink: useCase?.ctaInternalLink,
    ctaLink: useCase?.ctaLink,
    description: useCase?.description,
    heading: useCase?.heading,
    headingSize: useCase?.headingSize,
    icon: useCase?.icon,
    internalName: useCase?.internalName,
    subheading: useCase?.subheading,
    __typename: useCase?.__typename,
    id: useCase?.id,
  })
}