import React, { useState } from "react";
import Modal from "../../Modal";
import {
  TileWrap,
  TileHeading,
  TileSubheading,
  TileDescription,
  Label,
  LinkWrap,
} from "../styles/Tile.styled";
import { TileImageWrap } from "./TileImageWrap";
import { createVideoLinks, createReference } from "../utils/tilesUtil";

export const Tile = ({ tile, tiles, index, tileType }) => {
  const {
    heading,
    description,
    subheading,
    icon,
    videoLink,
  } = tile

  const [open, setOpen] = useState(false)
  const [videoId, setVideo] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)

  const openModal = (videoUrl, tileIndex) => {
    setVideo(videoUrl)
    setOpen(true)
    setActiveIndex(tileIndex)
  }

  const closeModal = () => {
    setVideo(null)
    setOpen(false)
    setActiveIndex(null)
  }

  const videoLinks = createVideoLinks(tiles)
  const reference = createReference(tile)

  if (videoLink) {
    return (
      <TileWrap>
        <div onClick={() => openModal(videoLink, index)}>
          {icon && <TileImageWrap icon={icon} videoLink={videoLink} />}
          {heading && <TileHeading>{heading}</TileHeading>}
          {subheading && <TileSubheading>{subheading}</TileSubheading>}
          {description && <TileDescription>{description?.description}</TileDescription>}
          <Label>Watch Now</Label>
        </div>
        {typeof window !== "undefined" && (
          <Modal
            show={open}
            onClose={closeModal}
            videoId={videoId}
            swiperLinks={videoLinks}
            reference={reference}
            tileIndex={activeIndex}
            thumbnail={icon}
          />
        )}
      </TileWrap>
    )
  }
  if (!videoLink) {
    return (
      <TileWrap tileType={tileType || 'Icon'}>
        <LinkWrap to={reference?.ctaButton?.link} style={{ textDecoration: "none" }}>
          {icon && <TileImageWrap icon={icon} videoLink={videoLink} />}
          {heading && <TileHeading>{heading}</TileHeading>}
          {subheading && <TileSubheading>{subheading}</TileSubheading>}
          {description && <TileDescription>{description?.description}</TileDescription>}
          {reference?.ctaButton?.link && <Label>{reference?.ctaButton?.label ? reference.ctaButton.label : 'Read More'}</Label>}
        </LinkWrap>
      </TileWrap>
    )
  }
}