import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import OptimizedImage from "../../utils/optimizedImage"
import { ConditionalWrapper } from "../../atoms/ConditionalWrapper"

// This component is a custom Image component based on this content type in Workgrids Contentful: https://app.contentful.com/spaces/z7p73u8c0thn/content_types/componentImage/fields

// Contentful's default Media component is too limited so we made this to have ability to add more fields.

export const ContentfulImage = ({ imageData, showCaption }) => {
  const { primaryImage } = imageData
  return (
    <>
      <ConditionalWrapper
        condition={imageData?.url && imageData?.internalLink}
        wrapper={children => <Link to={imageData?.url}>{children}</Link>}
      >
        <ConditionalWrapper
          condition={imageData?.url && !imageData?.internalLink}
          wrapper={children => (
            <a href={imageData?.url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )}
        >
          <OptimizedImage
            style={{ maxWidth: "100%", textAlign: "center" }}
            src={primaryImage.file?.url}
            alt={
              primaryImage?.description ||
              primaryImage?.title ||
              "componentImage"
            }
            image={primaryImage?.gatsbyImageData}
          />
        </ConditionalWrapper>
      </ConditionalWrapper>
      {imageData?.caption?.raw && (
        <Caption>
          <div
            style={{
              textAlign:
                imageData?.alignCaption === "center" ? "center" : "left",
              paddingTop: "8px",
            }}
          >
            {documentToReactComponents(JSON.parse(imageData?.caption?.raw))}
          </div>
        </Caption>
      )}
    </>
  )
}

ContentfulImage.displayName = "ContentfulImage"

const Caption = styled.p`
  padding-bottom: 16px;
  font-size: 12px;
  font-family: Roboto, "Roboto", sans-serif;
  color: #1b1b1b;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
`
