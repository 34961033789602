import React from "react";

import { ClearIcon, FilterIcon, SearchIcon } from "../../../utils/IconsList";
import onKeyDown from "../../../utils/onKeyDown";

import { Count, FilterButton, IconWrap, Search, SearchContainer, SearchWrap, } from "../styles/UseCaseFilters.styled";

const SearchArea = ({ isTablet, isMobile, setOverlayOpen, setActive, totalFilterCount, isSearching, setActiveKey, activeKey }) => {

  const handleKey = e => {
    if (e?.target.value.length) {
      setActiveKey(e.target.value)
    } else {
      setActiveKey("")
    }
  }

  const handleOpen = () => {
    setOverlayOpen(true)
    setActive('')
  }

  return (
    <SearchContainer isTablet={isTablet} isMobile={isMobile}>
      {isTablet &&
        <FilterButton
          onClick={() => !isSearching && handleOpen()}
          tabIndex={0}
          onKeyDown={(e) => onKeyDown(e, () => !isSearching && handleOpen())}
          role="button"
          aria-label="Open filters"
          aria-disabled={isSearching}
          searching={isSearching}
        >
          <IconWrap>
            <FilterIcon />
            {totalFilterCount > 0 && <Count>{totalFilterCount}</Count>}
          </IconWrap>
          {!isMobile && <span>Filter by category</span>}
        </FilterButton>
      }
      <SearchWrap isTablet={isTablet}>
        <Search onChange={handleKey} type="text" placeholder="Search app templates" isSearching={isSearching} value={activeKey} />
        <span id="search">
          <SearchIcon />
        </span>
        {isSearching &&
          <span id="clear" onClick={() => setActiveKey('')} role="button" tabIndex={0} aria-label="Clear search"
            onKeyDown={(e) => onKeyDown(e, () => setActiveKey(''))}>
            <ClearIcon id="clear" />
          </span>
        }
      </SearchWrap>
    </SearchContainer>
  )
}

export default SearchArea 
