import React, { useEffect, useState, useLayoutEffect, useMemo, useCallback } from "react";

import { paginator } from "../../utils/paginatorFunction";
import useScreenResponsive from "../../utils/screenResponsive";
import { formatUseCases } from "../../utils/formatUseCases";
import { useIsScrolled } from "../../hooks/useIsScrolled";
import { pushParams } from "../../utils/pushParams"

import UseCaseTile from "./components/UseCaseTile";
import UseCaseFilters from "./components/UseCaseFilters";
import ComponentPagination from "../Pagination/Pagination";
import DisplayCount from "../Integrations/components/DisplayCount";

import { ContentWrap, CountWrap, SorryHeading, SorrySubheading, SorryWrap, TileSectionWrap, TilesWrap, Title } from "./styles/UseCaseTileSection.styled";

import queryString from "query-string"

const UseCasesTileSection = ({ tiles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPosts, setMaxPosts] = useState(9)
  const [activeKey, setActiveKey] = useState("")
  const [searching, setSearching] = useState(false)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [activeIntegration, setActiveIntegration] = useState([])
  const [activeUseCase, setActiveUseCase] = useState([])
  const [activeUserGroup, setActiveUserGroup] = useState([])
  const [activeDropdown, setActiveDropdown] = useState("")
  const [filterParams, setFilterParams] = useState("")

  const hasFilter = activeIntegration.length > 0 || activeUseCase.length > 0 || activeUserGroup.length > 0

  const formattedTiles = []
  const integrations = []
  const useCases = []
  const userGroups = []

  tiles.forEach(tile => formattedTiles.push(formatUseCases(tile)))

  const { isScrolled } = useIsScrolled(600);
  const isMobile = useScreenResponsive(576)
  const isTablet = useScreenResponsive(992)

  const scrollToTop = (num) => {
    window.scrollTo({
      top: num || 610,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search)

    const parseFilterParamArray = (paramName) => {
      if (params[paramName]?.length > 0) {
        if (Array.isArray(params[paramName])) {
          return params[paramName]
        } else {
          return [params[paramName]]
        }
      } else {
        return []
      }
    }

    const updatedFilters = {
      activeKey: params?.searchField || "",
      activeIntegration: parseFilterParamArray("integration"),
      activeUseCase: parseFilterParamArray("useCase"),
      activeUserGroup: parseFilterParamArray("userGroup"),
    }

    setActiveKey(updatedFilters?.activeKey)
    setActiveIntegration(updatedFilters?.activeIntegration)
    setActiveUseCase(updatedFilters?.activeUseCase)
    setActiveUserGroup(updatedFilters?.activeUserGroup)
  }, [])

  const handleFilterParams = useCallback(() => {
    const params = []

    activeKey !== '' && params.push(`searchField=${activeKey}`)

    activeIntegration.length > 0 &&
      activeIntegration.forEach((integration) => {
        params.push(`integration=${integration.replaceAll('&', '%26')}`)
      })

    activeUseCase.length > 0 &&
      activeUseCase.forEach((useCase) => {
        params.push(`useCase=${useCase.replaceAll('&', '%26')}`)
      })

    activeUserGroup.length > 0 &&
      activeUserGroup.forEach((userGroup) => {
        params.push(`userGroup=${userGroup.replaceAll('&', '%26')}`)
      })

    const paramsString = params.length > 0 ? "?" + params.join("&") : ""

    setFilterParams(paramsString)
  }, [activeIntegration, activeUseCase, activeUserGroup, activeKey])

  useEffect(() => {
    pushParams(filterParams)
  }, [filterParams])

  useLayoutEffect(() => {
    handleFilterParams()
  }, [activeIntegration, activeUseCase, activeUserGroup, activeKey])

  useEffect(() => {
    isMobile ? setMaxPosts(6) : isTablet ? setMaxPosts(8) : setMaxPosts(9)
  }, [isMobile, isTablet])

  useEffect(() => {
    activeKey !== "" && setSearching(true);
    activeKey === "" && setSearching(false);
    setCurrentPage(1)
  }, [activeKey, searching])

  useEffect(() => {
    if (searching) {
      setActiveIntegration([])
      setActiveUseCase([])
      setActiveUserGroup([])
    }
  }, [searching])

  const filtered = useMemo(() =>
    formattedTiles &&
      formattedTiles.length > 0 ?
      formattedTiles.filter(
        (post) => {
          const noFilters = (activeIntegration.length === 0 && activeUseCase.length === 0 && activeUserGroup.length === 0 && activeKey === "")
          if (noFilters) return true

          const searchFilter = (activeKey !== ""
            && post?.heading?.toLowerCase().includes(activeKey?.toLowerCase()))

          const integrationsFilter = activeIntegration.length > 0
            && activeIntegration.some((int) => post?.integrations.includes(int))

          const useCasesFilter = activeUseCase.length > 0
            && activeUseCase.some((use) => post?.useCases.includes(use))

          const userGroupsFilter = activeUserGroup.length > 0
            && activeUserGroup.some((group) => post?.userGroups.includes(group))

          return (
            searchFilter ||
            (integrationsFilter ||
              useCasesFilter ||
              userGroupsFilter)
          )
        }
      ) : [], [activeIntegration, activeUseCase, activeUserGroup, activeKey, formattedTiles]
  )

  formattedTiles?.map((post) => {
    let postIntegrations
    let postUseCases
    let postUserGroups

    if (post?.integrations !== null) {
      postIntegrations = [...post?.integrations]
    }
    if (post?.useCases !== null) {
      postUseCases = [...post?.useCases]
    }
    if (post?.userGroups !== null) {
      postUserGroups = [...post?.userGroups]
    }

    postIntegrations && postIntegrations?.map(postInt => {
      if (!integrations.includes(postInt)) {
        if (postInt !== "New") {
          integrations.push(postInt)
        }
      }
    })
    postUseCases && postUseCases?.map(postCases => {
      if (!useCases.includes(postCases)) {
        useCases.push(postCases)
      }
    })
    postUserGroups && postUserGroups?.map(postGroups => {
      if (!userGroups.includes(postGroups)) {
        userGroups.push(postGroups)
      }
    })
  })

  let paginatedPostData = paginator(filtered, currentPage, maxPosts)

  let paginatedPosts = useMemo(() => paginatedPostData?.data?.map((tile, index) => {
    const isNew = tile?.category && tile?.category.some(cat => cat === "New")

    return (
      <UseCaseTile tile={tile} index={index} key={tile?.heading} isNew={isNew} />
    )
  }), [paginatedPostData])

  return (
    <TileSectionWrap>
      <Title>All App Templates</Title>
      <UseCaseFilters
        integrations={integrations}
        useCases={useCases}
        userGroups={userGroups}
        activeIntegration={activeIntegration}
        activeUseCase={activeUseCase}
        activeUserGroup={activeUserGroup}
        activeDropdown={activeDropdown}
        overlayOpen={overlayOpen}
        isTablet={isTablet}
        isMobile={isMobile}
        isScrolled={isScrolled}
        isSearching={searching}
        activeKey={activeKey}
        hasFilter={hasFilter}
        setActiveIntegration={setActiveIntegration}
        setActiveUseCase={setActiveUseCase}
        setActiveUserGroup={setActiveUserGroup}
        setActiveDropdown={setActiveDropdown}
        setActiveKey={setActiveKey}
        setOverlayOpen={setOverlayOpen}
        setCurrentPage={setCurrentPage}
        scrollToTop={scrollToTop}
      />
      <ContentWrap>
        <CountWrap>
          {filtered?.length > 0 && <DisplayCount page={currentPage} posts={filtered} postsPerPage={maxPosts} />}
          {filtered?.length === 0 &&
            <SorryWrap>
              <SorryHeading>
                Sorry, no results found.
              </SorryHeading>
              <SorrySubheading>
                Try a different search.
              </SorrySubheading>
            </SorryWrap>
          }
        </CountWrap>
        <TilesWrap>
          {paginatedPosts}
        </TilesWrap>
        <ComponentPagination
          current={currentPage}
          total={filtered.length}
          pageSize={maxPosts}
          func={setCurrentPage}
          marginTop={48}
        />
      </ContentWrap>
    </TileSectionWrap>
  )
}

export default UseCasesTileSection;
