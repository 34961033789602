import React from 'react'
import TileSectionStandard from "./TileSectionStandard"
import UseCasesTileSection from './UseCasesTileSection'

const TileSection = ({ component, slug }) => {
  const isUseCase = ["/use-cases", "/use-cases/","use-cases", "templates", "template"]

  switch (isUseCase.includes(slug)) {
    case (true): 
      return <UseCasesTileSection tiles={component?.tiles} />
    default:
      return <TileSectionStandard component={component} slug={slug} />
  }
}

export default TileSection